import 'html5-boilerplate/dist/js/vendor/modernizr-3.11.2.min.js';
import 'html5-boilerplate/dist/js/plugins.js';

import 'lazysizes';

const triggers = document.querySelectorAll('.io-trigger');
const navLinks = document.querySelectorAll('.header .nav__btn');
const navMobileLinks = document.querySelectorAll('.header-mobile .nav-mobile__btn');

let lastScrollY = window.scrollY;


import Utils from './helpers/utils.js';



let isInit = false;
let i = 0;
const observer = new IntersectionObserver((entries) => {
  entries.forEach(entry => {
    const targetId = entry.target.getAttribute('data-trigger');
    const activeLink = document.querySelector(`.nav__btn[href="#${targetId}"]`);

    const currentScrollY = window.scrollY;
    const scrollingDown = currentScrollY > lastScrollY;
    lastScrollY = currentScrollY;


    if(isInit) {
      if (scrollingDown)
        activateLink(targetId);
      else
        activatePreviousLink(targetId);
    } else {
      i++;
    }

    if(!isInit && i == entries.length)
      isInit = true;
  });
}, {
  rootMargin: '-100px 0px 0px 0px',
  threshold: 0
});


function activateLink(id) {
  navLinks.forEach(link => {
    link.classList.remove('active');
  });
  navMobileLinks.forEach(link => {
    link.classList.remove('active');
  });

  const activeLink = document.querySelector(`.nav__btn[href="#${id}"]`);
  const activeMobileLink = document.querySelector(`.nav-mobile__btn[href="#${id}"]`);
  if (activeLink) {
    activeLink.classList.add('active');
    activeMobileLink.classList.add('active');
    activeMobileLink.parentElement.classList.add('active');
    history.pushState(null, '', `#${id}`);
  }
}

function activatePreviousLink(currentId) {
  const currentIndex = Array.from(navLinks).findIndex(link => link.getAttribute('href') === `#${currentId}`);
  const currentMobileIndex = Array.from(navMobileLinks).findIndex(link => link.getAttribute('href') === `#${currentId}`);

  if (currentIndex > 0) {
    const previousLink = navLinks[currentIndex - 1];
    const previousMobileLink = navMobileLinks[currentMobileIndex - 1];

    activateLink(previousLink.getAttribute('href').substring(1));
    activateLink(previousMobileLink.getAttribute('href').substring(1));
    history.pushState(null, '', `${ previousLink.getAttribute('href') }`);
  } else {
    navLinks.forEach(el => el.classList.remove('active'));
    navMobileLinks.forEach(el => el.classList.remove('active'));
    navMobileLinks[0].parentElement.classList.remove('active');
    history.pushState(null, '', ' ');
  }
}



Utils.docReady(() => {
  document.querySelectorAll('[data-terms-open]').forEach(el => el.addEventListener('click', e => {
    e.stopPropagation();
    document.body.classList.add('terms-and-conditions--open')
  }));
  document.querySelector('[data-terms-close]').addEventListener('click', e => {
    e.stopPropagation();
    document.body.classList.remove('terms-and-conditions--open')
  });

  document.querySelectorAll('a[href="#privacy-policy"]').forEach(el => {
    el.removeAttribute('href');
    el.setAttribute('data-cookie-policy-open', '');
  });

  document.querySelectorAll('[data-cookie-policy-open]').forEach(el => el.addEventListener('click', e => {
    e.stopPropagation();
    document.body.classList.add('cookie-policy--open')
  }));
  document.querySelector('[data-cookie-policy-close]').addEventListener('click', e => {
    e.stopPropagation();
    document.body.classList.remove('cookie-policy--open')
  });


  //readmore
  document.querySelector('[data-read-more]').addEventListener('click', e => {
    e.stopPropagation();
    document.querySelector('[data-article]').classList.toggle('article--open')

    if(!document.querySelector('[data-article]').classList.contains('article--open'))
      document.querySelector('#maitake-by-the-sea').scrollIntoView();
  });


  //menu mobile
  document.body.addEventListener('click', () => {
    document.body.classList.remove('terms-and-conditions--open');
    document.body.classList.remove('cookie-policy--open');
    document.querySelector('.header-mobile').classList.remove('header-mobile--open')
  });

  document.querySelector('[data-hamb]').addEventListener('click', e => {
    e.stopPropagation();
    document.querySelector('.header-mobile').classList.toggle('header-mobile--open');
  });


  triggers.forEach(trigger => {
    observer.observe(trigger);
  });
});

let rafForResizeId;
window.addEventListener('scroll', event => {
  rafForResizeId = Utils.debouncedRequestAnimationFrame(rafForResizeId, () => {
    if(document.querySelector('.header-mobile').classList.contains('header-mobile--open'))
      document.querySelector('.header-mobile').classList.remove('header-mobile--open')
  });
});

